import { replace, push } from 'connected-react-router';

import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';

import ApiManager from 'utils/ApiManager'; // eslint-disable-line import/no-cycle
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import config from 'config';

const initialState = {
  loaderVisible: false,
  transparentLoaderVisible: false,
  drawerVisible: true,
  snackbarVisible: false,
  snackbarMessage: null,
  snackbarTimeout: null,
  title: null,
  selectedOrganization: {},
  selectedSurvey: {},
  sessionId: null,
  selectedLink: {},
  intervalId: null,
  uniqueIdVisible: false,
  uniqueIdHidden: false,
  userData: {},
  sessionPatient: {},
  loggedViaToken: false,
  isExaminationFromLink: false,
  isExaminationFromCode: false,
  groupSurveys: [],
  groupSurveyConfirmationInterval: null,
};

export const actionTypes = {
  SHOW_LOADER: 'GLOBAL/SHOW_LOADER',
  SHOW_TRANSPARENT_LOADER: 'GLOBAL/SHOW_TRANSPARENT_LOADER',
  HIDE_LOADER: 'GLOBAL/HIDE_LOADER',
  SHOW_SNACKBAR: 'GLOBAL/SHOW_SNACKBAR',
  HIDE_SNACKBAR: 'GLOBAL/HIDE_SNACKBAR',
  SET_SNACKBAR_TIMEOUT: 'GLOBAL/SET_SNACKBAR_TIMEOUT',
  SET_TITLE: 'GLOBAL/SET_TITLE',
  SET_SELECTED_ORGANIZATION: 'GLOBAL/SET_SELECTED_ORGANIZATION',
  SET_SELECTED_SURVEY: 'GLOBAL/SET_SELECTED_SURVEY',
  SET_SESSION_ID: 'GLOBAL/SET_SESSION_ID',
  SET_SELECTED_LINK: 'GLOBAL/SET_SELECTED_LINK',
  SET_INTERVAL: 'GLOBAL/SET_INTERVAL',
  SET_UNIQUE_ID_VISIBILITY: 'GLOBAL/SET_UNIQUE_ID_VISIBILITY',
  SET_UNIQUE_ID_HIDDEN: 'GLOBAL/SET_UNIQUE_ID_HIDDEN',
  CLEAR_APP_STORE: 'GLOBAL/CLEAR_APP_STORE',
  SHOW_DRAWER: 'GLOBAL/SHOW_DRAWER',
  HIDE_DRAWER: 'GLOBAL/HIDE_DRAWER',
  SET_USER_DATA: 'GLOBAL/SET_USER_DATA',
  SET_PATIENT_SESSION: 'GLOBAL/SET_PATIENT_SESSION',
  SET_LOGGED_VIA_TOKEN: 'GLOBAL/SET_LOGGED_VIA_TOKEN',
  SET_EXAMINATION_FROM_LINK: 'GLOBAL/SET_EXAMINATION_FROM_LINK',
  SET_EXAMINATION_FROM_CODE: 'GLOBAL/SET_EXAMINATION_FROM_CODE',
  SET_GROUP_SURVEYS_DATA: 'GLOBAL/SET_GROUP_SURVEYS_DATA',
  SET_GROUP_SURVEY_CODE_INTERVAL: 'GLOBAL/SET_GROUP_SURVEY_CODE_INTERVAL',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_APP_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.SHOW_LOADER: {
      return {
        ...state,
        loaderVisible: true,
        transparentLoaderVisible: false,
      };
    }

    case actionTypes.SHOW_TRANSPARENT_LOADER: {
      return {
        ...state,
        loaderVisible: false,
        transparentLoaderVisible: true,
      };
    }

    case actionTypes.HIDE_LOADER: {
      return {
        ...state,
        loaderVisible: false,
        transparentLoaderVisible: false,
      };
    }

    case actionTypes.SHOW_SNACKBAR: {
      return {
        ...state,
        snackbarVisible: true,
        snackbarMessage: action.message,
        messageFromApi: action.fromApi,
        snackbarType: action.snackbarType,
      };
    }

    case actionTypes.SET_SNACKBAR_TIMEOUT: {
      return {
        ...state,
        snackbarTimeout: action.timeout,
      };
    }

    case actionTypes.SHOW_DRAWER: {
      return {
        ...state,
        drawerVisible: true,
      };
    }

    case actionTypes.HIDE_DRAWER: {
      return {
        ...state,
        drawerVisible: false,
      };
    }

    case actionTypes.HIDE_SNACKBAR: {
      return {
        ...state,
        snackbarVisible: false,
        snackbarMessage: null,
      };
    }

    case actionTypes.SET_TITLE: {
      return {
        ...state,
        title: action.title,
      };
    }

    case actionTypes.SET_SELECTED_ORGANIZATION: {
      return {
        ...state,
        selectedOrganization: action.organization,
      };
    }

    case actionTypes.SET_SELECTED_SURVEY: {
      return {
        ...state,
        selectedSurvey: action.survey,
      };
    }

    case actionTypes.SET_SESSION_ID: {
      return {
        ...state,
        sessionId: action.sessionId,
      };
    }

    case actionTypes.SET_SELECTED_LINK: {
      return {
        ...state,
        selectedLink: action.link,
      };
    }

    case actionTypes.SET_INTERVAL: {
      return {
        ...state,
        intervalId: action.intervalId,
      };
    }

    case actionTypes.SET_UNIQUE_ID_VISIBILITY: {
      return {
        ...state,
        uniqueIdVisible: action.isUniqueIdVisible,
      };
    }

    case actionTypes.SET_UNIQUE_ID_HIDDEN: {
      return {
        ...state,
        uniqueIdHidden: action.isUniqueIdHidden,
      };
    }

    case actionTypes.SET_USER_DATA: {
      return {
        ...state,
        userData: action.userData,
      };
    }

    case actionTypes.SET_PATIENT_SESSION: {
      return {
        ...state,
        sessionPatient: action.sessionPatient,
      };
    }

    case actionTypes.SET_LOGGED_VIA_TOKEN: {
      return {
        ...state,
        loggedViaToken: action.loggedViaToken,
      };
    }

    case actionTypes.SET_EXAMINATION_FROM_LINK: {
      return {
        ...state,
        isExaminationFromLink: action.isExaminationFromLink,
      };
    }

    case actionTypes.SET_EXAMINATION_FROM_CODE: {
      return {
        ...state,
        isExaminationFromCode: action.isExaminationFromCode,
      };
    }

    case actionTypes.SET_GROUP_SURVEYS_DATA: {
      return {
        ...state,
        groupSurveys: action.groupSurveys,
      };
    }

    case actionTypes.SET_GROUP_SURVEY_CODE_INTERVAL: {
      return {
        ...state,
        groupSurveyConfirmationInterval: action.interval,
      };
    }

    default:
      return state;
  }
};

const setSnackbarTimeout = (timeout) => ({
  type: actionTypes.SET_SNACKBAR_TIMEOUT,
  timeout,
});

export const showDrawer = () => ({
  type: actionTypes.SHOW_DRAWER,
});

export const hideDrawer = () => ({
  type: actionTypes.HIDE_DRAWER,
});

export const showLoader = () => ({
  type: actionTypes.SHOW_LOADER,
});

export const showTransparentLoader = () => ({
  type: actionTypes.SHOW_TRANSPARENT_LOADER,
});

export const hideLoader = () => ({
  type: actionTypes.HIDE_LOADER,
});

export const showSnackbarAction = (message, fromApi, snackbarType) => ({
  type: actionTypes.SHOW_SNACKBAR,
  message,
  fromApi,
  snackbarType,
});

export const hideSnackbar = () => ({
  type: actionTypes.HIDE_SNACKBAR,
});

export const showSnackbar = (
  message, fromApi, type, withAutohide = true,
) => (dispatch, getStore) => {
  dispatch(showSnackbarAction(message, fromApi, type));
  const store = getStore().Global;
  const snackbarTimeout = store.snackbarTimeout;

  if (snackbarTimeout) {
    clearTimeout(snackbarTimeout);
  }

  if (withAutohide) {
    const timeout = setTimeout(() => {
      dispatch(hideSnackbar());
    }, config.hideSnackbarTimeout);
    dispatch(setSnackbarTimeout(timeout));
  }
};

export const clearAppStore = () => ({
  type: actionTypes.CLEAR_APP_STORE,
});

export const setUserData = (userData) => ({
  type: actionTypes.SET_USER_DATA,
  userData,
});

export const setSessionPatient = (sessionPatient) => ({
  type: actionTypes.SET_PATIENT_SESSION,
  sessionPatient,
});

export const setLoggedViaToken = (loggedViaToken) => ({
  type: actionTypes.SET_LOGGED_VIA_TOKEN,
  loggedViaToken,
});

export const setExaminationFromLink = (isExaminationFromLink) => ({
  type: actionTypes.SET_EXAMINATION_FROM_LINK,
  isExaminationFromLink,
});

export const setExaminationFromCode = (isExaminationFromCode) => ({
  type: actionTypes.SET_EXAMINATION_FROM_CODE,
  isExaminationFromCode,
});

export const setGroupSurveysData = (groupSurveys) => ({
  type: actionTypes.SET_GROUP_SURVEYS_DATA,
  groupSurveys,
});

export const setGroupSurveyConfirmationInterval = (interval) => ({
  type: actionTypes.SET_GROUP_SURVEY_CODE_INTERVAL,
  interval,
});

export const getFromStore = (dataKey) => (_, getStore) => _get(getStore(), dataKey, '');

export const setGroupExaminationInterval = (code) => (dispatch) => {
  ApiManager.request('put', dispatch, 'session_patient_groups_patients/connection_confirmation', { data: { code } });
  const groupSurveyInterval = setInterval(() => {
    ApiManager.request('put', dispatch, 'session_patient_groups_patients/connection_confirmation', { data: { code } });
  }, config.groupSurveyCodeConfirmationInterval);

  dispatch(setGroupSurveyConfirmationInterval(groupSurveyInterval));
};

export const clearGroupExaminationConfirmationInterval = () => (_, getStore) => {
  const { groupSurveyConfirmationInterval } = getStore().Global;
  clearInterval(groupSurveyConfirmationInterval);
};

export const logout = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());

  const { hostname } = getStore().Global.sessionPatient;

  return new Promise((resolve, reject) => {
    ApiManager.request('post', dispatch, 'logout', {}).then(() => {
      dispatch(hideLoader());
      dispatch(showSnackbar(snackbarMessages.logoutSuccess));
      dispatch(clearAppStore());
      if (hostname) {
        window.open(`${hostname}?logout=true`, '_self');
      } else {
        dispatch(replace('/login'));
      }
      resolve();
    }).catch((error) => {
      if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
      reject();
    });
  });
};

const getResumableSession = () => (dispatch) => {
  const url = createURLWithQuery('session_patients', { resumable: true });

  return ApiManager.request('get', dispatch, url);
};

export const getUserToken = () => (dispatch) => ApiManager.request('get', dispatch, 'user_token');

export const changeSessionStatus = (id, requestBody, token) => async (dispatch) => {
  const baseURL = config.adminApiHost;

  try {
    const requestOptions = {
      baseURL,
      headers: {
        Authorization: `Token ${token}`,
      },
      withCredentials: false,
    };

    return ApiManager.request('put', dispatch, `session_patients/${id}`, requestBody, false, requestOptions);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeGroupSessionStatus = (id, requestBody, code) => async (dispatch) => {
  const baseURL = config.adminApiHost;

  try {
    const requestOptions = {
      baseURL,
      withCredentials: false,
    };

    return ApiManager.request('put', dispatch, `session_patient_groups_patients/${code}/${id}/status`, requestBody, false, requestOptions);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeDiagnosticianSessionStatus = (id, requestBody) => async (dispatch) => {
  try {
    const tokenResponse = await dispatch(getUserToken());
    const token = tokenResponse.data.token;

    return dispatch(changeSessionStatus(id, requestBody, token));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const onSessionStatusChange = (status, note = '') => (dispatch, getStore) => {
  const { isExaminationFromLink, isExaminationFromCode, sessionPatient } = getStore().Global;
  const { id, authorizationToken } = sessionPatient;
  const requestBody = {
    status,
    note,
  };

  if (isExaminationFromLink) {
    requestBody.is_patient = true;
    return dispatch(changeSessionStatus(id, requestBody, authorizationToken));
  }

  if (isExaminationFromCode) {
    requestBody.is_patient = true;
    return dispatch(changeGroupSessionStatus(id, requestBody, sessionPatient.code));
  }

  return dispatch(changeDiagnosticianSessionStatus(id, requestBody));
};

const logoutUser = () => (dispatch, getStore) => {
  dispatch(clearAppStore());
  const { hostname } = getStore().Global.sessionPatient;
  if (hostname) {
    localStorage.clear();
    window.open(`${hostname}?logout=true`, '_self');
    return;
  }
  dispatch(replace('/login'));
  localStorage.clear();
};

export const checkIfUserIsLogged = () => (dispatch, getStore) => {
  const {
    userData: { role_id: roleId },
    sessionPatient: { id: sessionPatientId },
  } = getStore().Global;
  const { userRoles } = config;

  if (_includes([userRoles.diagnostician, userRoles.clinicCoordinator], roleId)) {
    return dispatch(getResumableSession()).then((response) => {
      const resumableSessionId = _get(response, 'data.id', null);
      if (resumableSessionId) {
        if (resumableSessionId !== sessionPatientId) {
          dispatch(logoutUser());
        } else {
          return Promise.resolve({ patientSession: true });
        }
      }

      if (_isEmpty(response.data)) {
        dispatch(push('/surveyFinished', { type: 'finished' }));
        return Promise.resolve({ patientNotExists: true });
      }

      return Promise.reject();
    }).catch((error) => {
      if (error.code === null) { // no connection
        return Promise.resolve(error);
      }
      dispatch(logoutUser());
      return Promise.reject();
    });
  }
  return Promise.resolve();
};

export const setTitle = (title) => ({
  type: actionTypes.SET_TITLE,
  title,
});

export const setSelectedOrganization = (organization) => ({
  type: actionTypes.SET_SELECTED_ORGANIZATION,
  organization,
});

export const setSelectedSurvey = (survey) => ({
  type: actionTypes.SET_SELECTED_SURVEY,
  survey,
});

export const setSessionId = (sessionId) => ({
  type: actionTypes.SET_SESSION_ID,
  sessionId,
});

export const setSelectedLink = (link) => ({
  type: actionTypes.SET_SELECTED_LINK,
  link,
});

const setIntervalId = (intervalId) => ({
  type: actionTypes.SET_INTERVAL,
  intervalId,
});

export const redirectAndClearStorage = () => (_, getStore) => {
  const { loggedViaToken, isExaminationFromCode, sessionPatient } = getStore().Global;
  const { hostname } = sessionPatient;

  localStorage.clear();

  if (loggedViaToken) {
    window.location.replace(`${config.adminAppUrl}/login`);
  } else if (isExaminationFromCode) {
    window.location.replace(`${hostname}?logout=true`);
  } else {
    window.location.pathname = '/';
  }
};

export const stopCounter = () => (dispatch, getStore) => {
  const store = getStore().Global;
  clearInterval(store.intervalId);
  dispatch(setIntervalId(null));
};

export const startCounting = (time) => (dispatch) => {
  const finishAt = new Date().getTime() + (time * 1000);

  const intervalId = setInterval(() => {
    const now = new Date().getTime();

    if (now >= finishAt) {
      dispatch(stopCounter());
      dispatch(push('/surveyFinished', { type: 'timesUp' }));
    }
  }, 1000);

  dispatch(setIntervalId(intervalId));
};

export const setUniqueIdVisibility = (isUniqueIdVisible) => ({
  type: actionTypes.SET_UNIQUE_ID_VISIBILITY,
  isUniqueIdVisible,
});

export const setUniqueIdHidden = (isUniqueIdHidden) => ({
  type: actionTypes.SET_UNIQUE_ID_HIDDEN,
  isUniqueIdHidden,
});
