import React from 'react';
import { replace, push } from 'connected-react-router';

import {
  showTransparentLoader,
  hideLoader,
  showSnackbar,
  showSnackbarAction,
  hideSnackbar,
} from 'containers/store';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

import ResetPasswordTokenError from 'components/ResetPasswordTokenError';

import { isTokenError } from './utils';

export const initialState = {
  initialValues: {
    login: '',
    password: '',
    confirmPassword: '',
  },
};

export const actionTypes = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const onClick = (login) => (dispatch) => {
  dispatch(hideSnackbar());
  dispatch(push('/forgotPassword', { login }));
};

export const onSubmit = (values, token) => (dispatch) => {
  dispatch(showTransparentLoader());

  const requestBody = {
    data: {
      token,
      password: values.password,
      password_confirmation: values.confirmPassword,
      email: values.login,
    },
  };

  ApiManager.request('post', dispatch, 'reset_password', requestBody).then(() => {
    dispatch(hideLoader());
    dispatch(showSnackbar(snackbarMessages.passwordChanged));
    dispatch(replace('/login'));
  }).catch((error) => {
    if (isTokenError(error)) {
      dispatch(showSnackbarAction(
        <ResetPasswordTokenError onClick={() => dispatch(onClick(values.login))} />,
      ));
    } else if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
