export default ((theme) => ({
  wrapper: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2.25),
    marginTop: theme.spacing(5),
  },
  republicOfPolandImage: {
    marginLeft: theme.spacing(3.09375),
  },
  nationalCentreImage: {
    marginLeft: theme.spacing(3.1625),
  },
  europeanUnionImage: {
    marginLeft: theme.spacing(3.1625),
  },
}));
