import { replace, push } from 'connected-react-router';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import {
  showLoader, hideLoader, logout, showSnackbar, showTransparentLoader,
  setSelectedOrganization, setSelectedSurvey, setSessionId,
} from 'containers/store';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

export const initialState = {
  isLoadedPage: false,
  items: [],
  surveys: [],
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'DASHBOARD/LOAD_PAGE_SUCCESS',
  SET_ITEMS: 'DASHBOARD/SET_ITEMS',
  UNSET_ACTIVE_SURVEYS: 'DASHBOARD/UNSET_ACTIVE_SURVEYS',
  UPDATE_SURVEYS: 'DASHBOARD/UPDATE_SURVEYS',
};

const generateItems = (items) => items.map((el, key) => ({
  id: key + 1,
  organizationId: el.id,
  name: el.name,
  address: `${el.street} ${el.number}, ${el.city}`,
}));

const generateSurveys = (items) => items.map((el) => ({
  id: el.id,
  organization: el.organization.name,
  name: el.survey.name,
  organizationUnit: el.organization_unit_code,
  numberOfSurveys: el.number_of_surveys,
  surveyStatus: el.active ? 'ACTIVE' : 'ENDED',
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: generateItems(action.responses.organizations.data),
        originalActiveSurveys: action.responses.activeSurveys.data,
        surveys: generateSurveys(action.responses.surveys.data),
      };
    }

    case actionTypes.SET_ITEMS: {
      return {
        ...state,
        items: generateItems(action.response.data),
      };
    }

    case actionTypes.UNSET_ACTIVE_SURVEYS: {
      const activeSurveys = [...state.activeSurveys];
      const originalActiveSurveys = [...state.originalActiveSurveys];
      const itemIndex = _findIndex(activeSurveys, (el) => el.id === action.id);
      const originalItemIndex = _findIndex(originalActiveSurveys, (el) => el.id === action.id);
      activeSurveys.splice(itemIndex, 1);
      originalActiveSurveys.splice(originalItemIndex, 1);

      return {
        ...state,
        activeSurveys,
        originalActiveSurveys,
      };
    }

    case actionTypes.UPDATE_SURVEYS: {
      return {
        ...state,
        surveys: generateSurveys(action.response.data),
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
});

const setItems = (response) => ({
  type: actionTypes.SET_ITEMS,
  response,
});

const unsetActiveSurveys = (id) => ({
  type: actionTypes.UNSET_ACTIVE_SURVEYS,
  id,
});

const updateSurveys = (response) => ({
  type: actionTypes.UPDATE_SURVEYS,
  response,
});

const loadOrganizations = (search) => (dispatch) => {
  let url = 'organizations';

  if (search) {
    url = `${url}?find=${search}`;
  }

  return ApiManager.request('get', dispatch, url);
};

const cancelActiveSurvey = (id) => (dispatch) => ApiManager.request('get', dispatch, `end_session/${id}`);

const getActiveSurveys = () => (dispatch) => ApiManager.request('get', dispatch, 'surveys');

const getSurveys = () => (dispatch) => ApiManager.request('get', dispatch, 'surveys/complex');

export const goToActiveSurvey = (id, originalActiveSurveys, items) => (dispatch) => {
  const selectedItem = _find(originalActiveSurveys, (el) => el.id === id);
  const selectedSurvey = {
    id: 0,
    surveyId: selectedItem.survey.id,
    name: selectedItem.survey.name,
  };

  const selectedOrganization = _find(
    items,
    (el) => el.organizationId === selectedItem.organization_id,
  );

  dispatch(setSelectedOrganization(selectedOrganization));
  dispatch(setSelectedSurvey(selectedSurvey));
  dispatch(setSessionId(selectedItem.id));
  dispatch(push('/surveysLinks'));
};

export const onActiveSurveyClick = (id) => (dispatch, getStore) => {
  const store = getStore().Dashboard;

  dispatch(goToActiveSurvey(id, store.originalActiveSurveys, store.items));
};

export const finishActiveSurvey = (id) => (dispatch) => {
  dispatch(showTransparentLoader());

  return new Promise((resolve, reject) => {
    dispatch(cancelActiveSurvey(id)).then(() => {
      dispatch(showSnackbar(snackbarMessages.surveyFinished));
      dispatch(hideLoader());
      resolve();
    }).catch((error) => {
      if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
      reject();
    });
  });
};

export const onActiveSurveyFinish = (id) => (dispatch) => {
  dispatch(finishActiveSurvey(id)).then(() => {
    dispatch(getSurveys()).then((response) => {
      dispatch(updateSurveys(response));
    }).catch(() => {
      dispatch(showSnackbar(snackbarMessages.globalError));
    });
    dispatch(unsetActiveSurveys(id));
  }).catch(() => {});
};

export const onSearchSubmit = (value, callback) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(loadOrganizations(value)).then((response) => {
    dispatch(setItems(response));
    dispatch(hideLoader());
    callback();
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(hideLoader());
    callback();
  });
};

export const loadPageData = () => (dispatch) => {
  dispatch(showLoader());

  PromiseAll({
    organizations: dispatch(loadOrganizations()),
    activeSurveys: dispatch(getActiveSurveys()),
    surveys: dispatch(getSurveys()),
  }).then((responses) => {
    dispatch(loadPageSuccess(responses));
    dispatch(hideLoader());
  }).catch(() => {
    dispatch(logout()).then(() => {
      dispatch(replace('/login'));
      dispatch(showSnackbar(snackbarMessages.globalError));
      dispatch(hideLoader());
    }).catch(() => {
      dispatch(replace('/login'));
      dispatch(showSnackbar(snackbarMessages.globalError));
      dispatch(hideLoader());
    });
  });
};
