import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const white = '#fff';
const green = '#06846D';
const textColor = '#16113B';
const menuHover = '#292549';
const greyButton = '#EBEBEF';

const theme = createMuiTheme({
  palette: {
    primary: { main: green },
    secondary: { main: '#FFA400' },
    yellow: '#FFD343',
    yellowWithOpacity: {
      10: '#FFFBED',
    },
    greyButton,
    text: {
      primary: textColor,
      secondary: white,
    },
    background: {
      main: grey[100],
      secondary: '#F7F9FC',
      white,
    },
    cardBackground: white,
    borderColor: grey[500],
    error: {
      main: '#D32F2F',
      secondary: '#F26C5D',
      tertiary: '#B10058',
    },
    success: { main: '#15C39A' },
    warning: { main: '#FF7A00' },
  },
  border: {
    grayThin: '1px solid rgba(31, 36, 60, 0.38)',
  },
  question: {
    answerBorder: '1px solid #06846D',
    ellipseBackground: '#06846D',
    ellipseBackgroundActive: '#FFD343',
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Red Hat Text',
    ],
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: white,
        '&:hover': {
          backgroundColor: '#06A285',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: white,
      },
    },
    MuiInputLabel: {
      formControl: {
        color: grey[400],
      },
      shrink: {
        color: grey[400],
      },
      outlined: {
        color: grey[400],
      },
    },
    MuiTableCell: {
      head: {
        color: grey[400],
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: grey[400],
      },
    },
    MuiList: {
      root: {
        backgroundColor: white,
      },
    },
    MuiTypography: {
      root: {
        color: textColor,
      },
      subtitle2: {
        color: white,
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: menuHover,
        borderRadius: 6,
      },
    },
  },
});

export default theme;
