import { push } from 'connected-react-router';

import surveyErrorCatch from 'utils/surveyErrorCatch';
import { clearQuestionsAndIndex } from 'containers/QuestionPage/store';

import {
  hideLoader,
  showTransparentLoader,
  showLoader,
  setUniqueIdHidden,
  hideDrawer,
  onSessionStatusChange,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  personName: '',
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'RESEARCH_START/LOAD_PAGE_SUCCESS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        personName: action.userName,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (userName) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  userName,
});

export const onSubmit = () => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(onSessionStatusChange('STARTED'))
    .then(() => {
      dispatch(hideLoader());
      dispatch(clearQuestionsAndIndex());
      dispatch(push('/questionPage', { patientSession: true, fromStartPage: true }));
    })
    .catch((error) => {
      surveyErrorCatch(error, dispatch, 'submitStart');
    });
};

export const loadPageData = () => (dispatch, getStore) => {
  const { first_name: firstName } = getStore().Global.sessionPatient.patient;

  dispatch(hideDrawer());
  dispatch(showLoader());
  dispatch(setUniqueIdHidden(false));
  dispatch(loadPageSuccess(firstName));
  dispatch(hideLoader());
};
