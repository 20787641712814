const fetchVersionInJSON = async () => {
  try {
    const response = await fetch('/version.json', {
      headers: {
        'Cache-Control': 'no-cache',
      },
    });

    if (response.ok) {
      return await response.json();
    }
    throw response;
  } catch (e) {
    /* eslint-disable no-console */
    console.group('Possibly file not found. Please check your networks for /version.json request.');
    console.error('Error: ', e);
    console.groupEnd();
    /* eslint-enable no-console */

    return null;
  }
};

export default fetchVersionInJSON;
