import { push } from 'connected-react-router';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {
  showTransparentLoader,
  hideLoader,
  showLoader,
  showSnackbar,
  stopCounter,
  hideDrawer,
  checkIfUserIsLogged,
  setSessionPatient,
  onSessionStatusChange,
  setGroupSurveysData,
} from 'containers/store';
import { getSessionPatient, getPatientGroupSurveys } from 'containers/Login/store';

import config from 'config';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import errorCatch from 'utils/errorCatch';
import { setOnTryAgainCallback } from 'containers/SurveyFinished/store';

export const initialState = {
  isLoadedPage: false,
  patientSession: false,
  questions: [],
  currentIndex: 0,
  savedValues: {},
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'QUESTION_PAGE/LOAD_PAGE_SUCCESS',
  INCREMENT_INDEX: 'QUESTION_PAGE/INCREMENT_INDEX',
  CLEAR_STORE_LOADED: 'QUESTION_PAGE/CLEAR_STORE_LOADED',
  CLEAR_QUESTIONS_AND_INDEX: 'QUESTION_PAGE/CLEAR_QUESTIONS_AND_INDEX',
  SET_INDEX: 'QUESTION_PAGE/SET_INDEX',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const { questions, fromStartPage, currentQuestion } = action;

      return {
        ...state,
        isLoadedPage: true,
        questions,
        currentIndex: fromStartPage ? currentQuestion - 1 : state.currentIndex,
        patientSession: action.patientSession,
      };
    }

    case actionTypes.INCREMENT_INDEX: {
      return {
        ...state,
        currentIndex: state.currentIndex + 1,
      };
    }

    case actionTypes.SET_INDEX: {
      return {
        ...state,
        currentIndex: action.newIndex,
      };
    }

    case actionTypes.CLEAR_STORE_LOADED: {
      return {
        ...state,
        isLoadedPage: false,
      };
    }

    case actionTypes.CLEAR_QUESTIONS_AND_INDEX: {
      return {
        ...state,
        currentIndex: 0,
        questions: [],
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (questions, currentQuestion, fromStartPage, patientSession) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  questions,
  currentQuestion,
  fromStartPage,
  patientSession,
});

export const incrementIndex = () => ({
  type: actionTypes.INCREMENT_INDEX,
});

const setIndex = (newIndex) => ({
  type: actionTypes.SET_INDEX,
  newIndex,
});

const getQuestionsWithAnswers = (response) => {
  const questions = [];

  _forEach(response.data, (el) => {
    const answerSetTypeId = _get(el, 'question.answer_set.answer_set_type_id', -1);

    questions.push({
      id: el.question_id,
      mappingId: el.id,
      question: el.question.name,
      image: el.question.image || null,
      answers: el.question.answer_set.answers.map((el1) => ({
        id: el1.id,
        answer: el1.label,
      })),
      progressStatus: el.progress_status_label,
      isLast: el.is_last,
      isCommunicate: el.question.question_type_id === 6,
      isOpenAnswer: answerSetTypeId === config.answerTypeId.openAnswer,
      answersMapping: el.answer.map((el1) => ({
        id: el1.id,
        nextQuestion: el1.pivot.next_survey_question_id,
      })),
    });
  });

  return questions;
};

export const finishSessions = () => (dispatch, getStore) => {
  const store = getStore();
  const sessionId = store.Global.sessionId;
  const body = {
    data: {
      uniqueid: store.Global.selectedLink.questionId,
      status: 'ENDED',
    },
  };

  return ApiManager.request('post', dispatch, `session/${sessionId}/survey`, body);
};

const endCurrentSurveySession = () => (dispatch, getStore) => {
  const baseURL = config.adminApiHost;
  const { sessionPatient } = getStore().Global;
  const { id, code } = sessionPatient;
  const requestOptions = {
    baseURL,
    withCredentials: false,
  };

  const requestBody = {
    status: 'ENDED',
    is_patient: true,
  };

  return ApiManager.request('put', dispatch, `session_patient_groups_patients/${code}/${id}/status`, requestBody, false, requestOptions);
};

export const clearStore = () => ({
  type: actionTypes.CLEAR_STORE_LOADED,
});

export const clearQuestionsAndIndex = () => ({
  type: actionTypes.CLEAR_QUESTIONS_AND_INDEX,
});

const goToNextQuestion = (answerId, formikMethods) => (dispatch, getStore) => {
  const { currentIndex, questions, patientSession } = getStore().QuestionPage;

  const mapping = _find(questions[currentIndex].answersMapping, ['id', answerId]);

  if (mapping) {
    const nextQuestionIndex = _findIndex(questions, ['mappingId', mapping.nextQuestion]);
    dispatch(setIndex(nextQuestionIndex));
  } else {
    dispatch(incrementIndex());
  }

  if (formikMethods && formikMethods.resetForm) {
    formikMethods.resetForm();
  }
  dispatch(push('questionPage', { patientSession }));
};

const getRequestBody = (values) => (_, getStore) => {
  const {
    Global: {
      sessionId,
      sessionPatient,
      selectedLink,
      isExaminationFromCode,
    },
    QuestionPage: { currentIndex, questions },
  } = getStore();

  const currentQuestion = questions[currentIndex];

  const questionID = currentQuestion.id;
  const { isCommunicate, isOpenAnswer } = currentQuestion;
  const textAnswer = values.textAnswer;
  let answerID = values.answer;

  if (isCommunicate) {
    answerID = currentQuestion.answers[0].id;
  }

  const answerData = {
    answer_id: isOpenAnswer ? null : answerID,
    question_id: questionID,
    is_group: isExaminationFromCode,
    ...(isOpenAnswer && { text_answer: textAnswer }),
    ...(_isEmpty(sessionPatient) && {
      uniqueid: selectedLink.questionId,
      session_id: sessionId,
    }),
  };

  return { data: answerData };
};

export const getSessionParticipant = (sessionId) => (dispatch) => ApiManager.request('get', dispatch, `session_participant/${sessionId}`);

const getSessionData = () => (dispatch, getStore) => {
  const {
    Global: { sessionPatient, selectedLink },
  } = getStore();

  const isPatientSession = !_isEmpty(sessionPatient);

  if (isPatientSession) {
    return dispatch(getSessionPatient(sessionPatient.id));
  }

  return dispatch(getSessionParticipant(selectedLink.questionId));
};

export const loadPageData = (routeState) => (dispatch, getStore) => {
  if (routeState.patientSession) {
    dispatch(hideDrawer());
  }

  const {
    selectedSurvey,
    selectedLink,
    sessionPatient,
  } = getStore().Global;

  dispatch(checkIfUserIsLogged()).then((currentSession) => {
    if (currentSession && currentSession.patientNotExists) {
      return;
    }

    const isPatientSession = routeState.patientSession
     || (currentSession && currentSession.patientSession);
    const url = isPatientSession
      ? `session_patients/${_get(sessionPatient, 'id')}/questions`
      : `survey/${_get(selectedSurvey, 'surveyId')}/questions/${_get(selectedLink, 'questionId')}`;

    dispatch(showLoader());

    ApiManager.request('get', dispatch, url)
      .then((response) => {
        const questions = getQuestionsWithAnswers(response);
        dispatch(loadPageSuccess(
          questions,
          sessionPatient.current_questions_counter,
          routeState.fromStartPage,
          isPatientSession,
        ));
        dispatch(hideLoader());
      })
      .catch((error) => {
        if (error.code === null) { // no connection
          dispatch(hideLoader());
          dispatch(setOnTryAgainCallback('push', ['questionPage', { patientSession: routeState.patientSession }]));
          dispatch(push('/surveyFinished', { type: 'noConnection' }));
        } else {
          errorCatch(error, dispatch);
        }
      });
  }).catch((error) => {
    if (error && error.code === null) { // no connection
      dispatch(hideLoader());
      dispatch(setOnTryAgainCallback('push', ['questionPage', { patientSession: routeState.patientSession }]));
      dispatch(push('/surveyFinished', { type: 'noConnection' }));
    }
  });
};

export const onSubmit = (values, formikMethods) => (dispatch, getStore) => {
  const {
    Global: { sessionPatient, isExaminationFromCode, groupSurveys },
    QuestionPage: { currentIndex, questions, patientSession },
  } = getStore();
  const isPatientSession = !_isEmpty(sessionPatient);

  const requestUrl = isPatientSession ? `session_patients/${sessionPatient.id}/results` : 'results';

  const isLastQuestion = currentIndex + 1 === questions.length || questions[currentIndex].isLast;

  dispatch(showTransparentLoader());

  ApiManager.request('post', dispatch, requestUrl, dispatch(getRequestBody(values)))
    .then(async () => {
      if (isExaminationFromCode) {
        const currentSessionIndex = _findIndex(
          groupSurveys, (survey) => survey.id === sessionPatient.id,
        );
        if (isLastQuestion && currentSessionIndex === groupSurveys.length - 1) {
          dispatch(push('/groupSurveyFinish'));
        } else if (isLastQuestion && currentSessionIndex !== groupSurveys.length - 1) {
          dispatch(stopCounter());
          dispatch(showTransparentLoader());

          await dispatch(endCurrentSurveySession());

          // fetch and overwrite surveys ready to start
          const newPatientSurveys = await dispatch(getPatientGroupSurveys(sessionPatient.code));
          dispatch(setGroupSurveysData(newPatientSurveys.data));

          // get next survey questions
          const nextSession = _find(newPatientSurveys.data, (survey) => survey.status === 'CREATED');
          const sessionData = await dispatch(getSessionPatient(nextSession.id));
          const currentQuestionsCounter = sessionData.data.current_questions_counter;

          // set next session of questions
          dispatch(setSessionPatient({
            ...sessionPatient,
            id: nextSession.id,
            current_questions_counter: currentQuestionsCounter,
          }));
          if (formikMethods && formikMethods.resetForm) {
            formikMethods.resetForm();
          }

          // start survey and redirect to next question
          dispatch(onSessionStatusChange('STARTED'))
            .then(() => {
              dispatch(clearQuestionsAndIndex());
              dispatch(setIndex(0));
              dispatch(loadPageData({ patientSession }));
            }).catch((error) => {
              if (isBadRequest(error)) {
                dispatch(showSnackbar(snackbarMessages.wrongData));
              } else {
                dispatch(showSnackbar(snackbarMessages.globalError));
              }

              dispatch(hideLoader());
            });
        } else {
          // next question
          dispatch(goToNextQuestion(values.answer, formikMethods));
        }

        dispatch(hideLoader());
        return;
      }

      if (isLastQuestion) {
        if (_isEmpty(sessionPatient)) {
          dispatch(finishSessions())
            .then(() => {
              dispatch(stopCounter());
              dispatch(push('/surveyFinished', { type: 'finished' }));
            })
            .catch((error) => { errorCatch(error, dispatch); });
        } else {
          dispatch(stopCounter());
          dispatch(push('/researchEnd'));
        }
      } else {
        // next question
        dispatch(goToNextQuestion(values.answer, formikMethods));
      }

      dispatch(hideLoader());
    })
    .catch((error) => {
      if (error.code === null) { // no connection
        dispatch(setOnTryAgainCallback('submitQuestion', [values, formikMethods]));
        dispatch(push('/surveyFinished', { type: 'noConnection' }));
      } else if (error.code === 418) { // times up
        dispatch(push('/surveyFinished', { type: 'timesUp' }));
      } else if (error.code === 409) {
        dispatch(push('/examinationTerminated'));
      } else if (error.code === 423) {
        dispatch(push('/surveyFinished', { type: 'finished' }));
      } else if (error.code === 422) {
        dispatch(getSessionData()).then((response) => {
          const {
            overall_questions_counter: overallQuestionCounter,
            current_questions_counter: currentQuestionCounter,
          } = response.data;
          if (currentQuestionCounter === overallQuestionCounter) {
            if (isPatientSession) {
              dispatch(push('/researchEnd'));
            } else {
              dispatch(push('/surveyFinished', { type: 'finished' }));
            }
          } else {
            const nextQuestionIndex = currentQuestionCounter - 1;
            formikMethods.resetForm();
            dispatch(push('questionPage', { patientSession: isPatientSession }));
            dispatch(setIndex(nextQuestionIndex));
          }
        });
      } else if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
};

export const onSuspend = () => (dispatch) => {
  dispatch(push('/surveyPinPad', { pinPadType: 'suspend' }));
};

export const onAbort = () => (dispatch) => {
  dispatch(push('/surveyPinPad', { pinPadType: 'break' }));
};
