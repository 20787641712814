const config = {
  appVersion: '1.6.3',
  closeSurveyPin: '7586',
  localApiHost: process.env.REACT_APP_API_URL || 'https://master-diagmatic-front-api.fingoweb.it/',
  adminAppUrl: process.env.REACT_APP_ADMIN_APP_URL || 'https://master-diagmatic-admin-client.fingoweb.it/',
  adminApiHost: process.env.REACT_APP_ADMIN_API_URL || 'https://master-diagmatic-admin-api.fingoweb.it/',
  localApp: process.env.REACT_APP_FRONT_CLIENT_URL || 'https://master-diagmatic-front-client.fingoweb.it',
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  sentryEnv: process.env.REACT_APP_SENTRY_ENV || 'master',
  env: process.env.REACT_APP_ENV || 'master',
  apiUrl: 'api',
  pathsToOmit: [
    'login',
    'forgotPassword',
    'resetPassword',
    'remoteExaminationEnd',
    'loginByCode',
    'examinationsList',
  ],
  answerTypeId: {
    openAnswer: 5,
  },
  maxOpenAnswerLength: 255,
  maxNoteLength: 65535,
  hideSnackbarTimeout: 4000,
  passwordMinLength: 8,
  snackbarTypes: {
    error: 'error',
    info: 'info',
  },
  userRoles: {
    diagnostician: 'diagnostician',
    clinicCoordinator: 'clinic_coordinator',
  },
  minDeviceWidth: 360,
  defaultPageWidth: 496,
  versionCheckInterval: 5000,
  groupSurveyCodeConfirmationInterval: 30000,
};

export default config;
