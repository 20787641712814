import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { classesShape } from 'utils/shapes/classesShape';
import { intlShape } from 'utils/shapes/intl.shape';

import messages from './ResetPasswordTokenError.messages';
import styles from './ResetPasswordTokenError.styles';

const ResetPasswordTokenError = ({
  intl,
  classes,
  onClick,
}) => (
  <div className={classes.wrapper}>
    <Typography className={classes.infoText}>
      {intl.formatMessage(messages.content)}
    </Typography>
    &nbsp;
    <div onClick={onClick} className={classes.link}>
      <Typography className={classes.errorText}>
        {intl.formatMessage(messages.linkMessage)}
      </Typography>
    </div>
  </div>
);

ResetPasswordTokenError.propTypes = {
  classes: classesShape.isRequired,
  intl: intlShape.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(injectIntl(ResetPasswordTokenError));
