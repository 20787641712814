import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _includes from 'lodash/includes';
import _trim from 'lodash/trim';
import { replace, push } from 'connected-react-router';

import {
  showTransparentLoader,
  hideLoader,
  showLoader,
  setUserData,
  setSessionPatient,
  setLoggedViaToken,
  hideSnackbar,
  setExaminationFromLink,
  clearAppStore,
  showSnackbar,
} from 'containers/store';
import snackbarMessages from 'utils/snackbarMessages';
import ApiManager from 'utils/ApiManager';
import errorCatch from 'utils/errorCatch';
import config from 'config';

export const initialState = {
  isLoadedPage: false,
  apiVersion: null,
  initialValues: {
    code: '',
  },
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'LOGIN_BY_CODE_PAGE/LOAD_PAGE_SUCCESS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        apiVersion: action.apiVersion,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (apiVersion) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  apiVersion,
});

const getSessionPatients = () => (dispatch) => ApiManager.request('get', dispatch, 'session_patients');

const handleDiagnosticianAccount = (response, loggedByToken = false) => (dispatch) => {
  const specificUserData = _pick(response.data, ['email', 'first_name', 'last_name', 'id', 'role_id']);
  dispatch(setUserData(specificUserData));
  const { userRoles } = config;
  const roleId = _get(response, 'data.role_id');
  if (_includes([userRoles.diagnostician, userRoles.clinicCoordinator], roleId)) {
    dispatch(getSessionPatients())
      .then((resp) => {
        if (loggedByToken) {
          dispatch(setLoggedViaToken(true));
        }

        if (_get(resp, 'data.session.survey.id', '')) {
          dispatch(setSessionPatient(resp.data));
          dispatch(replace('/researchStart'));
        } else {
          dispatch(replace('/examinationsList'));
        }
      })
      .catch((error) => { errorCatch(error, dispatch); });
  } else {
    dispatch(replace('/dashboard'));
  }
};

export const getSessionPatient = (sessionId) => (dispatch) => ApiManager.request('get', dispatch, `session_patients/${sessionId}`);

const handlePatientAccount = (sessionId, authorizationToken) => async (dispatch) => {
  try {
    const sessionData = await dispatch(getSessionPatient(sessionId));
    const patientName = sessionData.data.patient.first_name;
    const currentQuestionsCounter = sessionData.data.current_questions_counter;
    dispatch(setUserData({ name: patientName }));
    dispatch(setExaminationFromLink(true));

    dispatch(setSessionPatient({
      id: sessionId,
      patient: { first_name: patientName },
      current_questions_counter: currentQuestionsCounter,
      authorizationToken,
    }));
    dispatch(replace('/researchStart'));
  } catch (error) {
    if (error.code === 409) {
      dispatch(showSnackbar(snackbarMessages.examinationIsOver));
      dispatch(hideLoader());
    } else if (error.code === 418) {
      dispatch(push('/surveyFinished', { type: 'timesUp' }));
    } else if (error.code === 423) {
      dispatch(push('/remoteExaminationEnd'));
    } else {
      errorCatch(error, dispatch);
    }
  }
};

export const login = (values) => (dispatch) => {
  dispatch(showTransparentLoader());
  dispatch(hideSnackbar());

  const trimmedCode = _trim(values.code);

  const requestBody = {
    data: {
      code: trimmedCode,
    },
  };

  ApiManager.request('post', dispatch, 'login/session_patient_groups_patients', requestBody)
    .then(() => {
      dispatch(clearAppStore());
      const location = window.location;
      const hostname = `${location.protocol}//${location.host}${location.pathname}`;
      window.open(`${config.localApp}/login?code=${trimmedCode}&hostname=${hostname}`, '_self');

      dispatch(hideLoader());
    })
    .catch((error) => {
      let message = snackbarMessages[_get(error, 'error.errors.code[0]')];
      if (!message) {
        message = snackbarMessages[_get(error, 'error.errors.patient[0]')];
      }

      if (message) {
        dispatch(showSnackbar(message, false, config.snackbarTypes.error));
        dispatch(hideLoader());
        return;
      }

      const snackbarOptions = {
        showMessageFromApi: true,
        snackbarType: config.snackbarTypes.error,
        snackbarAutohide: false,
      };
      errorCatch(error, dispatch, false, true, snackbarOptions);
    });
};

const loginWithToken = (requestBody) => (dispatch) => ApiManager.request('post', dispatch, 'login/token', requestBody);

export const loadPageData = (match, routeState) => (dispatch) => {
  const token = _get(match, 'params.token');
  const sessionId = _get(match, 'params.sessionId');

  const isRedirectFromLogout = new URLSearchParams(window.location.search).get('logout');
  if (isRedirectFromLogout) {
    dispatch(showSnackbar(snackbarMessages.logoutSuccess));
  }
  dispatch(showLoader());
  if (token && sessionId) {
    const requestBody = { data: { token, remember: true, is_patient: true } };

    dispatch(loginWithToken(requestBody)).then(() => {
      dispatch(clearAppStore());
      dispatch(handlePatientAccount(sessionId, token));
    }).catch((error) => {
      dispatch(replace('/remoteExaminationEnd', { type: 'invalidToken' }));
      errorCatch(error, dispatch);
    });
  } else if (token) {
    const requestBody = { data: { token, remember: true } };

    dispatch(loginWithToken(requestBody)).then((response) => {
      dispatch(clearAppStore());
      dispatch(handleDiagnosticianAccount(response, true));
    }).catch((error) => { errorCatch(error, dispatch); });
  } else {
    ApiManager.request('get', dispatch, 'ping', null, true)
      .then((response) => {
        const apiVersion = response.headers.version;
        dispatch(hideLoader());
        dispatch(loadPageSuccess(apiVersion));
        if (routeState.showSnackbar) {
          dispatch(showSnackbar(routeState.snackbarMessage));
          dispatch(replace());
        }
      })
      .catch((error) => {
        if (_get(error, 'response.status', null)) {
          errorCatch(error, dispatch);
        }
      });
  }
};
