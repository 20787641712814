export default (theme) => ({
  wrapper: {
    display: 'flex',
    lineHeight: '16px',
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
  },
  errorText: {
    lineHeight: 1,
    color: theme.palette.secondary.main,
  },
  infoText: {
    lineHeight: 1,
    color: theme.palette.text.secondary,
  },
});
