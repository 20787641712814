import config from 'config';

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import _throttle from 'lodash/throttle';
import _omit from 'lodash/omit';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
import { rootReducer } from 'containers/reducers';
import setValues from 'utils/setValues';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('diagmaticState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(setValues(state, {
      'Global.snackbarMessage': null,
      'Global.snackbarVisible': false,
    }));
    localStorage.setItem('diagmaticState', serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

export default function initStore(history) {
  const middleware = [
    thunkMiddleware,
    routerMiddleware(history),
  ];

  const urlParams = new URLSearchParams(history.location.search);
  if (history.location.pathname === '/login' && urlParams.get('code') && urlParams.get('hostname')) {
    localStorage.clear();
  }

  const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware));

  const persistedState = loadState();
  const routerLocation = _get(persistedState, 'router.location.pathname', '');
  const shouldOmitRouter = _includes(
    config.pathsToOmit,
    routerLocation.split('/')[1],
  );

  const store = createStore(
    rootReducer(history),
    shouldOmitRouter ? _omit(persistedState, ['router']) : persistedState,
    composedEnhancers,
  );

  store.subscribe(_throttle(() => {
    saveState(store.getState());
  }, 1000));

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../containers/reducers', () => {
      const nextReducers = require('../containers/reducers'); // eslint-disable-line global-require
      store.replaceReducer(nextReducers);
    });
  }

  return store;
}
