import { push } from 'connected-react-router';

import ApiManager from 'utils/ApiManager';
import surveyErrorCatch from 'utils/surveyErrorCatch';

import {
  hideLoader,
  showTransparentLoader,
} from 'containers/store';

export const onBreak = ({ note }) => (dispatch) => {
  dispatch(push('/surveyPinPad', { pinPadType: 'breakConfirm', note }));
};

export const onPauseEnd = (values, sendNote = false) => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const {
    Global: { sessionPatient },
    QuestionPage: { currentIndex, questions },
  } = getStore();

  const questionID = questions[currentIndex].id;

  const body = {
    data: {
      question_id: questionID,
      is_suspended: false,
    },
  };

  if (values.note && sendNote) {
    body.data.note = values.note;
  }

  ApiManager.request('post', dispatch, `session_patients/${sessionPatient.id}/results`, body)
    .then(() => {
      dispatch(hideLoader());
      dispatch(push('/questionPage', { patientSession: true }));
    })
    .catch((error) => {
      surveyErrorCatch(error, dispatch, 'onPauseEnd', [values, sendNote]);
    });
};
