import { isValidElement as isReactValidElement } from 'react';
import _isString from 'lodash/isString';

const resolveSnackbarMessage = (snackbarMessage, intl) => {
  if (!snackbarMessage) {
    return null;
  }

  if (isReactValidElement(snackbarMessage) || _isString(snackbarMessage)) {
    return snackbarMessage;
  }

  return intl.formatMessage(snackbarMessage);
};

export { resolveSnackbarMessage };
