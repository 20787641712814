import { goBack, push } from 'connected-react-router';

import _fill from 'lodash/fill';
import _isEmpty from 'lodash/isEmpty';

import config from 'config';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import errorCatch from 'utils/errorCatch';
import surveyErrorCatch from 'utils/surveyErrorCatch';

import {
  showLoader,
  hideLoader,
  showTransparentLoader,
  logout,
  showSnackbar,
  redirectAndClearStorage,
  onSessionStatusChange,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    pin: [..._fill(Array(4), null)],
  },
  note: '',
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SURVEY_PIN_PAD/LOAD_PAGE_SUCCESS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        note: action.note,
        isLoadedPage: true,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (note) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  note,
});

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

const suspendSession = () => async (dispatch, getStore) => {
  const {
    Global: { sessionPatient },
    QuestionPage: { currentIndex, questions },
  } = getStore();

  const questionID = questions[currentIndex].id;

  const body = {
    data: {
      question_id: questionID,
      is_suspended: true,
    },
  };

  await ApiManager.request('post', dispatch, `session_patients/${sessionPatient.id}/results`, body);
};

const onSuspend = (isStopped = false) => async (dispatch) => {
  try {
    dispatch(suspendSession());
    dispatch(push('/ExaminationSuspend', { isStopped }));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onStatusChange = (status, statusChangeNote) => async (dispatch, getStore) => {
  const { note } = getStore().SurveyPinPad;

  const sessionNote = _isEmpty(statusChangeNote) ? note : statusChangeNote;

  try {
    await dispatch(onSessionStatusChange(status, sessionNote));
    await logout();
    dispatch(redirectAndClearStorage());
  } catch (error) {
    surveyErrorCatch(error, dispatch, 'onStatusChange', [status, statusChangeNote]);
  }
};

export const onSubmit = (pin, type, resetPinPad) => (dispatch) => {
  dispatch(showTransparentLoader());

  if (pin.join('') === config.closeSurveyPin) {
    switch (type) {
      case 'suspend':
        dispatch(onSuspend());

        break;
      case 'break':
        dispatch(onSuspend(true));

        break;
      case 'breakConfirm':
        dispatch(onStatusChange('SUSPENDED'));

        break;
      case 'end':
        dispatch(onStatusChange('ENDED'));

        break;

      default:
        break;
    }
  } else {
    dispatch(showSnackbar(snackbarMessages.incorrectPin));
    resetPinPad();
    dispatch(hideLoader());
  }
};

export const loadPageData = ({ note }) => (dispatch) => {
  dispatch(showLoader());
  dispatch(loadPageSuccess(note));
  dispatch(hideLoader());
};
