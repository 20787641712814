import forEach from 'lodash/forEach';

const createURLWithQuery = (url, data, stringify) => {
  const ret = [];

  forEach(data, (value, key) => {
    const finalValue = stringify ? JSON.stringify(value) : value;

    if (data[key] !== undefined) {
      ret.push(`${key}=${encodeURIComponent(finalValue)}`);
    }
  });

  return `${url}?${ret.join('&')}`;
};

export default createURLWithQuery;
