import _get from 'lodash/get';
import _includes from 'lodash/includes';

import config from 'config';

export default (browserHistory, store) => {
  const history = browserHistory;
  const state = store.getState();
  const routerLocation = _get(state, 'router.location.pathname', '');
  const prevLocation = routerLocation.split('/')[1];
  const newLocation = history.location.pathname.split('/')[1];
  const desktopExaminationRegexp = /login\/.+/;
  const disableRedirect = _includes(
    config.pathsToOmit,
    prevLocation,
  ) || history.location.pathname.match(desktopExaminationRegexp);

  if (!disableRedirect && newLocation !== routerLocation) {
    const routerState = _get(state, 'router.location.state', {});

    history.replace(routerLocation, routerState);
  }

  return browserHistory;
};
