import { push } from 'connected-react-router';
import { setTitle, setUniqueIdHidden, showLoader } from 'containers/store';
import { onPauseEnd } from '../ExaminationSuspend/store';
import { onSubmit as submitQuestion } from '../QuestionPage/store';
import { onSubmit as submitStart } from '../ResearchStart/store';
import { onStatusChange } from '../SurveyPinPad/store';
import { onSubmit as submitUserData } from '../UserData/store';

export const initialState = {
  isLoadedPage: false,
  type: null,
  onTryAgain: () => {},
  tryAgainAgruments: [],
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SURVEY_FINISHED/LOAD_PAGE_SUCCESS',
  SET_ACTION_TYPE: 'SURVEY_FINISHED/SET_ACTION_TYPE',
  SET_TRY_AGAIN_CALLBACK: 'SURVEY_FINISHED/SET_TRY_AGAIN_CALLBACK',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
      };
    }

    case actionTypes.SET_ACTION_TYPE: {
      return {
        ...state,
        type: action.screenType,
      };
    }

    case actionTypes.SET_TRY_AGAIN_CALLBACK: {
      return {
        ...state,
        tryAgainFunctionType: action.tryAgainFunctionType,
        tryAgainAgruments: action.tryAgainAgruments,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = () => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
});

const setActionType = (screenType) => ({
  type: actionTypes.SET_ACTION_TYPE,
  screenType,
});

export const setOnTryAgainCallback = (tryAgainFunctionType, tryAgainAgruments) => ({
  type: actionTypes.SET_TRY_AGAIN_CALLBACK,
  tryAgainFunctionType,
  tryAgainAgruments,
});

export const onTryAgain = () => (dispatch, getStore) => {
  const store = getStore();

  const tryAgainCallbacksMap = {
    push,
    submitQuestion,
    onPauseEnd,
    submitStart,
    onStatusChange,
    submitUserData,
  };

  const tryAgainCallback = tryAgainCallbacksMap[store.SurveyFinished.tryAgainFunctionType];
  const tryAgainArguments = store.SurveyFinished.tryAgainAgruments;

  if (tryAgainCallback) {
    dispatch(showLoader());
    dispatch(tryAgainCallback(...tryAgainArguments));
  }
};

export const loadPageData = (routeState) => (dispatch) => {
  dispatch(setActionType(routeState.type));
  dispatch(loadPageSuccess());

  if (routeState.type === 'finished') {
    dispatch(setTitle());
    dispatch(setUniqueIdHidden(true));
  }
};
