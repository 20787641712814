import _forEach from 'lodash/forEach';
import _set from 'lodash/set';

const setValues = (object, mapper) => {
  let acc = object;

  _forEach(mapper, (value, key) => {
    acc = _set(acc, key, value);
  });

  return acc;
};

export default setValues;
