import React from 'react';
import { Route } from 'react-router-dom';
import UnauthorizedLayout from 'components/UnauthorizedLayout';

const UnauthorizedRoute = ({ component: Component, ...rest }) => ( // eslint-disable-line react/prop-types
  <Route
    {...rest}
    render={(props) => (
      <UnauthorizedLayout alternativeLayout={rest.alternativeLayout}>
        <Component {...props} />
      </UnauthorizedLayout>
    )}
  />
);

export default UnauthorizedRoute;
