import React from 'react';
import { Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import _includes from 'lodash/includes';

import AsyncComponent from 'components/AsyncComponent';
import { historyShape } from 'utils/shapes/router.shapes';
import getDomain from 'utils/getDomain';
import { subdomains } from 'consts/subdomains';

import AuthorizedRoute from './AuthorizedRoute';
import UnauthorizedRoute from './UnauthorizedRoute';
import Global from '../global';

const asyncLoadedPages = {
  LoginPage: AsyncComponent(() => import('containers/Login')),
  DashboardPage: AsyncComponent(() => import('containers/Dashboard')),
  SurveysListPage: AsyncComponent(() => import('containers/SurveysList')),
  GradeDataPage: AsyncComponent(() => import('containers/GradeData')),
  SurveysLinksPage: AsyncComponent(() => import('containers/SurveysLinks')),
  ForgotPasswordPage: AsyncComponent(() => import('containers/ForgotPassword')),
  UserDataPage: AsyncComponent(() => import('containers/UserData')),
  QuestionPage: AsyncComponent(() => import('containers/QuestionPage')),
  SurveyFinished: AsyncComponent(() => import('containers/SurveyFinished')),
  ResetPassword: AsyncComponent(() => import('containers/ResetPassword')),
  ExaminationSuspend: AsyncComponent(() => import('containers/ExaminationSuspend')),
  ResearchEnd: AsyncComponent(() => import('containers/ResearchEnd')),
  ExaminationsList: AsyncComponent(() => import('containers/ExaminationsList')),
  ResearchStart: AsyncComponent(() => import('containers/ResearchStart')),
  ExaminationTerminated: AsyncComponent(() => import('containers/ExaminationTerminated')),
  SurveyPinPad: AsyncComponent(() => import('containers/SurveyPinPad')),
  RemoteExaminationEndRoute: AsyncComponent(() => import('containers/RemoteExaminationEnd')),
  LoginByCode: AsyncComponent(() => import('containers/LoginByCode')),
  GroupSurveyFinish: AsyncComponent(() => import('containers/GroupSurveyFinish')),
  AnonymousUserData: AsyncComponent(() => import('containers/AnonymousUserData')),
};

const Routes = ({ history }) => {
  const domain = getDomain();

  return (
    <>
      {_includes(domain, subdomains.groupSurveyLogin) ? (
        <Global history={history}>
          <Switch>
            <UnauthorizedRoute exact component={asyncLoadedPages.LoginByCode} path="/login" alternativeLayout />
            <Redirect
              to={{
                pathname: '/login',
                search: history.location.search,
              }}
            />
          </Switch>
        </Global>
      ) : (
        <Global history={history}>
          <Switch>
            <UnauthorizedRoute exact component={asyncLoadedPages.LoginPage} path="/login" />
            <UnauthorizedRoute component={asyncLoadedPages.ForgotPasswordPage} path="/forgotPassword" />
            <UnauthorizedRoute component={asyncLoadedPages.ResetPassword} path="/resetPassword/:token" alternativeLayout />
            <UnauthorizedRoute component={asyncLoadedPages.LoginPage} path="/login/:token/:sessionId" />
            <UnauthorizedRoute component={asyncLoadedPages.LoginPage} path="/login/:token" />
            <asyncLoadedPages.RemoteExaminationEndRoute path="/remoteExaminationEnd" />
            <AuthorizedRoute component={asyncLoadedPages.DashboardPage} path="/dashboard" />
            <AuthorizedRoute component={asyncLoadedPages.SurveysListPage} path="/surveysList" />
            <AuthorizedRoute component={asyncLoadedPages.GradeDataPage} path="/gradeData" />
            <AuthorizedRoute component={asyncLoadedPages.SurveysLinksPage} path="/surveysLinks" />
            <AuthorizedRoute component={asyncLoadedPages.UserDataPage} path="/userData" />
            <AuthorizedRoute component={asyncLoadedPages.QuestionPage} path="/questionPage" />
            <AuthorizedRoute component={asyncLoadedPages.SurveyFinished} path="/surveyFinished" />
            <AuthorizedRoute component={asyncLoadedPages.ExaminationSuspend} path="/examinationSuspend" />
            <AuthorizedRoute component={asyncLoadedPages.ResearchEnd} path="/researchEnd" />
            <AuthorizedRoute component={asyncLoadedPages.ExaminationsList} path="/examinationsList" />
            <AuthorizedRoute component={asyncLoadedPages.ResearchStart} path="/researchStart" />
            <AuthorizedRoute component={asyncLoadedPages.ExaminationTerminated} path="/examinationTerminated" />
            <AuthorizedRoute component={asyncLoadedPages.SurveyPinPad} path="/surveyPinPad" />
            <AuthorizedRoute component={asyncLoadedPages.GroupSurveyFinish} path="/groupSurveyFinish" />
            <AuthorizedRoute component={asyncLoadedPages.AnonymousUserData} path="/anonymousUserData" />
            <Redirect
              to={{
                pathname: '/login',
                search: history.location.search,
              }}
            />
          </Switch>
        </Global>
      )}
    </>
  );
};

Routes.propTypes = {
  history: historyShape.isRequired,
};

export default Routes;
