import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _isEqual from 'lodash/isEqual';
import _forEach from 'lodash/forEach';
import * as Sentry from '@sentry/react';

import { historyShape, locationShape } from 'utils/shapes/router.shapes';

const disableGoBackForRoutes = ['/surveyFinished', '/questionPage', '/userData'];

class Global extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    history: historyShape.isRequired,
    location: locationShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      historyStack: [],
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', this.browserButtonsClickEventHandler);
    window.addEventListener('error', this.errorEventHandler);

    this.onHistoryChange(this.props.history.location, 'PUSH');
  }

  UNSAFE_componentWillReceiveProps(nextProps) { //eslint-disable-line
    if (this.props.location.pathname !== nextProps.location.pathname
      || !_isEqual(this.props.location.state, nextProps.location.state)) {
      this.onHistoryChange(nextProps.location);
    }
  }

  componentDidCatch(err) {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.captureException(err);
    }
    this.props.history.push('/surveyFinished', { type: 'error' });
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.browserButtonsClickEventHandler);
    window.removeEventListener('error', this.errorEventHandler);
  }

  onHistoryChange = (location) => {
    this.setState((prevState) => {
      const historyStack = [...prevState.historyStack];
      historyStack.push(location);

      return {
        historyStack,
      };
    });
  };

  browserButtonsClickEventHandler = (event) => {
    const prevState = this.state.historyStack[this.state.historyStack.length - 2];
    let disableGoBack = false;

    _forEach(disableGoBackForRoutes, (el) => {
      const regexp = new RegExp(el);
      if (prevState.pathname.match(regexp)) {
        disableGoBack = true;
      }
    });

    if (disableGoBack) {
      event.preventDefault();
      this.props.history.go(1);
    }
  };

  redirectToLogin = () => {
    window.location.pathname = '/';
  };

  render() {
    return this.props.children;
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  ...state.ResetPassword,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Global));
