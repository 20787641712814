import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import _values from 'lodash/values';

import { withStyles } from '@material-ui/core/styles';
import logo from 'assets/img/diagmaticLogo.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import config from 'config';
import Founders from 'components/Founders';
import { classesShape } from 'utils/shapes/classesShape';
import { intlShape } from 'utils/shapes/intl.shape';
import { hideSnackbar as hideSnackbarCallback } from 'containers/store';
import people from 'assets/img/loginimage.svg';

import unauthorizedLayoutStyles from './UnauthorizedLayout.styles';
import { resolveSnackbarMessage } from './UnauthorizedLayout.utils';
import messages from './UnauthorizedLayout.messages';

const AlternativeLayout = ({ isAlternative, children, classes }) => (isAlternative
  ? (
    <div className={classes.leftSide}>
      <div className={classes.leftSideContent}>
        {children}
      </div>
    </div>
  )
  : children
);

AlternativeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: classesShape,
  isAlternative: PropTypes.bool,
};

AlternativeLayout.defaultProps = {
  isAlternative: false,
  classes: {},
};

const UnauthorizedLayout = ({
  children, classes, loaderVisible, transparentLoaderVisible,
  hideSnackbar, snackbarVisible, snackbarMessage, intl, apiVersion, snackbarType,
  alternativeLayout,
}) => (
  <>
    {
      loaderVisible || transparentLoaderVisible ? (
        <div className={classNames({
          [classes.loaderWrapper]: true,
          [classes.transparentLoaderWrapper]: transparentLoaderVisible,
        })}
        >
          {
            transparentLoaderVisible ? (
              <div className={classes.transparentLoaderBg} />
            ) : null
          }
          <div className={classes.progressWrapper}>
            <CircularProgress color="primary" />
          </div>
        </div>
      ) : null
    }
    <div className={classes.wrapper}>
      <AlternativeLayout isAlternative={alternativeLayout} classes={classes}>
        <div className={classNames({
          [classes.contentWrapper]: true,
          [classes.contentWrapperWithLoader]: loaderVisible,
          [classes.alternativeContentWrapper]: alternativeLayout,
        })}
        >
          <div className={classNames(classes.content, {
            [classes.alternativeContent]: alternativeLayout,
          })}
          >
            <div>
              <div className={classes.logoWrapper}>
                <img src={logo} alt="diagmatic" />
              </div>
              {children}
            </div>
            <Founders />
          </div>
          {!alternativeLayout && (
            <Typography className={classes.version}>
              {`${intl.formatMessage(messages.appVersion)} ${config.appVersion}`}
              <br />
              {`${intl.formatMessage(messages.apiVersion)} ${apiVersion}`}
            </Typography>
          )}
        </div>
      </AlternativeLayout>
      {alternativeLayout && (
        <div className={classes.rightSide}>
          <img className={classes.image} src={people} alt="people" />
        </div>
      )}
    </div>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={snackbarVisible}
      onClose={hideSnackbar}
      message={resolveSnackbarMessage(snackbarMessage, intl)}
      ContentProps={{
        classes: {
          root: classNames({
            [classes.errorSnackbar]: snackbarType === config.snackbarTypes.error,
          }),
        },
      }}
    />
  </>
);

UnauthorizedLayout.propTypes = {
  children: PropTypes.node.isRequired,
  hideSnackbar: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  loaderVisible: PropTypes.bool.isRequired,
  snackbarVisible: PropTypes.bool.isRequired,
  transparentLoaderVisible: PropTypes.bool.isRequired,
  alternativeLayout: PropTypes.bool,
  apiVersion: PropTypes.string,
  classes: classesShape,
  snackbarMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  snackbarType: PropTypes.oneOf(_values(config.snackbarTypes)),
};

UnauthorizedLayout.defaultProps = {
  alternativeLayout: false,
  classes: {},
  snackbarMessage: null,
  apiVersion: '',
  snackbarType: config.snackbarTypes.info,
};

const mapDispatchToProps = {
  hideSnackbar: hideSnackbarCallback,
};

const mapStateToProps = (state) => ({
  ...state.Global,
  ...state.Login,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(unauthorizedLayoutStyles)(injectIntl(UnauthorizedLayout)));
