import { replace } from 'connected-react-router';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

import {
  hideLoader,
  showTransparentLoader,
  showLoader,
  hideDrawer,
  onSessionStatusChange,
  showSnackbar,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'GROUP_SURVEY_FINISH/LOAD_PAGE_SUCCESS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = () => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
});

export const onSubmit = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());

  const { hostname } = getStore().Global.sessionPatient;

  dispatch(onSessionStatusChange('ENDED')).then(() => {
    ApiManager.request('post', dispatch, 'logout', {}).then(() => {
      localStorage.clear();
      if (hostname) {
        window.open(`${hostname}?logout=true`, '_self');
      } else {
        dispatch(replace('/login'));
      }
      dispatch(hideLoader());
    }).catch((error) => {
      if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });

  dispatch(hideLoader());
};

export const loadPageData = () => (dispatch) => {
  dispatch(hideDrawer());
  dispatch(showLoader());
  dispatch(loadPageSuccess());
  dispatch(hideLoader());
};
