import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import europeanFoundations from 'assets/img/europeanFoundations.svg';
import europeanUnion from 'assets/img/europeanUnion.svg';
import nationalCentreResearchAndDevelopment
  from 'assets/img/nationalCentreResearchAndDevelopment.svg';
import republicOfPoland from 'assets/img/republicOfPoland.svg';
import { classesShape } from 'utils/shapes/classesShape';
import config from 'config';

import foundersStyles from './Founders.styles';

const getScaleFactor = () => {
  if (window.innerWidth < config.defaultPageWidth) {
    return (window.innerWidth - 64) / config.defaultPageWidth;
  }

  if (window.innerWidth < config.minDeviceWidth) {
    return 320 / (config.defaultPageWidth + 64);
  }

  return 1;
};

class Founders extends React.Component {
  static propTypes = {
    classes: classesShape.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      scaleFactor: getScaleFactor(),
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.setScaleFactor);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScaleFactor);
  }

  setScaleFactor = () => {
    this.setState({
      scaleFactor: getScaleFactor(),
    });
  }

  render() {
    const { classes } = this.props;
    const { scaleFactor } = this.state;

    return (
      <div
        className={classes.wrapper}
        style={{ transform: `scale(${scaleFactor})` }}
      >
        <img src={europeanFoundations} alt="europeanFoundations" />
        <img
          className={classes.republicOfPolandImage}
          src={republicOfPoland}
          alt="republicOfPoland"
        />
        <img
          className={classes.nationalCentreImage}
          src={nationalCentreResearchAndDevelopment}
          alt="nationalCentreResearchAndDevelopment"
        />
        <img
          className={classes.europeanUnionImage}
          src={europeanUnion}
          alt="europeanUnion"
        />
      </div>
    );
  }
}

export default withStyles(foundersStyles)(Founders);
