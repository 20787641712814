import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

import _isEmpty from 'lodash/isEmpty';

import {
  setGroupExaminationInterval,
  clearGroupExaminationConfirmationInterval,
} from 'containers/store';
import PageLayout from 'components/PageLayout';

const AuthorizedRoute = ({
  component: Component, isLogged, code, ...rest // eslint-disable-line react/prop-types
}) => {
  useEffect(() => {
    if (code) {
      rest.setGroupExaminationInterval(code);
    }

    return () => {
      rest.clearGroupExaminationConfirmationInterval();
    };
  }, []);

  return (
    isLogged ? (
      <Route
        {...rest}
        render={(props) => (
          <PageLayout>
            <Component {...props} />
          </PageLayout>
        )}
      />
    ) : (
      <Redirect
        to={{ pathname: '/login' }}
      />
    )
  );
};
const mapStateToProps = (state) => ({
  isLogged: !_isEmpty(state.Global.userData),
  code: state.Global.sessionPatient.code,
});

const mapDispatchToProps = {
  setGroupExaminationInterval,
  clearGroupExaminationConfirmationInterval,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthorizedRoute));
