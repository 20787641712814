import { defineMessages } from 'react-intl';

export default defineMessages({
  globalError: 'Wystąpił problem podczas wczytywania. Spróbuj ponownie później.',
  succesfullyResetedPassword: 'Jeśli użytkownik istnieje, link do resetu hasła został wysłany na e-mail',
  wrongData: 'Niepoprawne dane',
  logoutSuccess: 'Zostałeś pomyślnie wylogowany',
  passwordChanged: 'Hasło zostało zmienione',
  surveyFinished: 'Ankieta zakończona',
  surveyIsAlreadyActive: 'Masz już jedną aktywną ankietę',
  noAvailableSurveysToStart: 'Brak dostępnych ankiet',
  incorrectPin: 'Niepoprawny kod pin',
  sessionIsOver: 'Twoja sesja wygasła. Zaloguj się ponownie',
  examinationIsOver: 'Badanie zostało zakończone',
  nicknameAlreadySetInExamination: 'Pseudonim już użyty w tym badaniu grupowym.',
  'auth.incorrect_code': 'Niepoprawny kod badania',
  'auth.patient_is_during_session': 'Kod jest już w użyciu',
  'auth.session_is_ended': 'Kod badania został już wykorzystany',
  'auth.patient_is_deleted': 'Użytkownik został usunięty',
});
