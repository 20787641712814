import { push } from 'connected-react-router';
import {
  hideLoader, showSnackbar, showTransparentLoader, setSessionId,
} from 'containers/store';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

export const initialState = {
  initialValues: {
    gradeName: '',
    count: '',
  },
  gradeName: '',
  count: '',
  showDialog: false,
};

export const actionTypes = {
  SHOW_DIALOG: 'GRADE_DATA/SHOW_DIALOG',
  HIDE_DIALOG: 'GRADE_DATA/HIDE_DIALOG',
  SAVE_VALUES: 'GRADE_DATA/SAVE_VALUES',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_DIALOG: {
      return {
        ...state,
        showDialog: true,
      };
    }

    case actionTypes.HIDE_DIALOG: {
      return {
        ...state,
        showDialog: false,
      };
    }

    case actionTypes.SAVE_VALUES: {
      return {
        ...state,
        gradeName: action.values.gradeName,
        count: action.values.count,
      };
    }

    default:
      return state;
  }
};

const showDialog = () => ({
  type: actionTypes.SHOW_DIALOG,
});

const saveValues = (values) => ({
  type: actionTypes.SAVE_VALUES,
  values,
});

export const hideDialog = () => ({
  type: actionTypes.HIDE_DIALOG,
});

export const generateLinks = () => (dispatch, getStore) => {
  dispatch(hideDialog());
  dispatch(showTransparentLoader());

  const store = getStore();

  const organizationId = store.Global.selectedOrganization.organizationId;
  const surveyId = store.Global.selectedSurvey.surveyId;

  const requestBody = {
    data: {
      organization_unit_code: store.GradeData.gradeName,
      number_of_surveys: parseInt(store.GradeData.count),
    },
  };

  const url = `organization/${organizationId}/survey/${surveyId}/session`;

  ApiManager.request('post', dispatch, url, requestBody).then((response) => {
    dispatch(setSessionId(response.data.id));
    dispatch(push('/surveysLinks'));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else if (error.code === 400) {
      dispatch(showSnackbar(snackbarMessages.surveyIsAlreadyActive));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const checkSurveyExists = (values) => (dispatch, getStore) => {
  dispatch(saveValues(values));
  const store = getStore();

  const unitCode = values.gradeName;
  const organizationId = store.Global.selectedOrganization.organizationId;
  const surveyId = store.Global.selectedSurvey.surveyId;

  const url = `session/${organizationId}/${surveyId}/${unitCode}/exists`;

  ApiManager.request('get', dispatch, url).then((response) => {
    const exists = response.data;

    if (exists) {
      dispatch(showDialog());
    } else {
      dispatch(generateLinks());
    }
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
  });
};
