import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import _includes from 'lodash/includes';

import { actionTypes as globalActionTypes, reducer as Global } from 'containers/store';
import getDomain from 'utils/getDomain';
import { subdomains } from 'consts/subdomains';

// page reducers
import { reducer as Login } from 'containers/Login/store';
import { reducer as ForgotPassword } from 'containers/ForgotPassword/store';
import { reducer as Dashboard } from 'containers/Dashboard/store';
import { reducer as SurveysList } from 'containers/SurveysList/store';
import { reducer as GradeData } from 'containers/GradeData/store';
import { reducer as SurveysLinks } from 'containers/SurveysLinks/store';
import { reducer as UserData } from 'containers/UserData/store';
import { reducer as SurveyFinished } from 'containers/SurveyFinished/store';
import { reducer as QuestionPage } from 'containers/QuestionPage/store';
import { reducer as ResetPassword } from 'containers/ResetPassword/store';
import { reducer as ResearchStart } from 'containers/ResearchStart/store';
import { reducer as SurveyPinPad } from 'containers/SurveyPinPad/store';
import { reducer as LoginByCode } from 'containers/LoginByCode/store';
import { reducer as GroupSurveyFinish } from 'containers/GroupSurveyFinish/store';
import { reducer as AnonymousUserData } from 'containers/AnonymousUserData/store';

const standardReducers = {
  Login,
  ForgotPassword,
  Dashboard,
  SurveysList,
  GradeData,
  SurveysLinks,
  UserData,
  SurveyFinished,
  QuestionPage,
  ResetPassword,
  ResearchStart,
  SurveyPinPad,
  GroupSurveyFinish,
  AnonymousUserData,
};

const groupSurveyReducers = {
  LoginByCode,
};

const appReducer = (history) => {
  let reducers = standardReducers;
  if (_includes(getDomain(), subdomains.groupSurveyLogin)) {
    reducers = groupSurveyReducers;
  }

  return combineReducers({
    router: connectRouter(history),
    Global,
    ...reducers,
  });
};

export const rootReducer = (history) => (state, action) => {
  let newState = { ...state };

  if (action.type === globalActionTypes.CLEAR_APP_STORE) {
    // clear store
    newState = undefined;
  }

  return appReducer(history)(newState, action);
};
