import { push } from 'connected-react-router';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

import {
  hideLoader,
  showSnackbar,
} from 'containers/store';
import { setOnTryAgainCallback } from 'containers/SurveyFinished/store';

const surveyErrorCatch = (err, dispatch, onTryAgain, tryAgainAgruments = []) => {
  if (err.code === null) { // no connection
    dispatch(setOnTryAgainCallback(onTryAgain, tryAgainAgruments));
    dispatch(push('/surveyFinished', { type: 'noConnection' }));
  } else if (isBadRequest(err)) {
    dispatch(showSnackbar(snackbarMessages.wrongData));
  } else {
    dispatch(showSnackbar(snackbarMessages.globalError));
  }

  dispatch(hideLoader());
};

export default surveyErrorCatch;
