export default (theme) => ({
  wrapper: {
    width: '100vw',
    minHeight: '100vh',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.background.main}`,
    backgroundColor: theme.palette.background.secondary,
    position: 'relative',
  },
  header: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
    height: 48,
    borderBottom: `2px solid ${theme.palette.background.main}`,
    padding: '0 12px',
    backgroundColor: theme.palette.background.white,
  },
  logoWrapper: {
    display: 'flex',
    width: 42,
  },
  logo: {
    margin: 'auto',
  },
  headerContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 24px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  userData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  pageContent: {
    height: 'calc(100% - 72px)',
    padding: 12,
  },
  loadingPageContent: {
    padding: 0,
  },
  idWrapper: {
    whiteSpace: 'nowrap',
    paddingRight: theme.spacing(1.5),
  },
  loaderWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  transparentLoaderWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100,
  },
  transparentLoaderBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.cardBackground,
    opacity: 0.6,
    zIndex: 100,
  },
  progressWrapper: {
    position: 'relative',
    zIndex: 101,
  },
});
