import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';

import { classesShape } from 'utils/shapes/classesShape';
import { intlShape } from 'utils/shapes/intl.shape';
import logoSmall from 'assets/img/logo_small.png';
import { logout as logoutCallback, hideSnackbar as hideSnackbarCallback } from 'containers/store';
import pageLayoutStyles from './PageLayout.styles';

import messages from './PageLayout.messages';

const PageLayout = ({
  classes, children, uniqueid, intl, loaderVisible,
  transparentLoaderVisible, title, snackbarVisible, hideSnackbar,
  snackbarMessage, uniqueIdVisible, uniqueIdHidden, drawerVisible, logout,
}) => (
  <div className={classes.wrapper}>
    {drawerVisible ? (
      <div className={classes.header}>
        <div className={classes.logoWrapper}>
          <img src={logoSmall} alt="Diagmatic" className={classes.logo} />
        </div>
        <div className={classNames({
          [classes.headerContent]: true,
        })}
        >
          <Typography
            variant="h6"
          >
            {title}
          </Typography>
        </div>
        <div className={classes.userData}>
          {
                uniqueIdVisible && uniqueid ? (
                  <div className={classes.idWrapper}>
                    {
                      uniqueIdHidden ? null : (
                        <Typography>
                          ID
                          {' '}
                          {uniqueid}
                        </Typography>
                      )
                    }
                  </div>
                ) : (
                  <Button
                    onClick={logout}
                    variant="contained"
                    color="primary"
                  >
                    {intl.formatMessage(messages.logout)}
                  </Button>
                )
              }
        </div>
      </div>
    ) : null}
    {
          loaderVisible || transparentLoaderVisible ? (
            <div className={classNames({
              [classes.loaderWrapper]: true,
              [classes.transparentLoaderWrapper]: transparentLoaderVisible,
            })}
            >
              {
                transparentLoaderVisible ? (
                  <div className={classes.transparentLoaderBg} />
                ) : null
              }
              <div className={classes.progressWrapper}>
                <CircularProgress color="primary" />
              </div>
            </div>
          ) : null
        }
    <div className={classNames({
      [classes.pageContent]: true,
      [classes.loadingPageContent]: loaderVisible,
    })}
    >
      {children}
    </div>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={snackbarVisible}
      onClose={hideSnackbar}
      message={snackbarMessage ? intl.formatMessage(snackbarMessage) : null}
    />
  </div>
);

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  drawerVisible: PropTypes.bool.isRequired,
  hideSnackbar: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  loaderVisible: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  snackbarVisible: PropTypes.bool.isRequired,
  transparentLoaderVisible: PropTypes.bool.isRequired,
  classes: classesShape,
  snackbarMessage: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  title: PropTypes.string,
  uniqueid: PropTypes.string,
  uniqueIdHidden: PropTypes.bool,
  uniqueIdVisible: PropTypes.bool,
};

PageLayout.defaultProps = {
  uniqueid: null,
  classes: {},
  snackbarMessage: null,
  title: null,
  uniqueIdVisible: false,
  uniqueIdHidden: false,
};

const mapDispatchToProps = {
  logout: logoutCallback,
  hideSnackbar: hideSnackbarCallback,
};

const mapStateToProps = (state) => ({
  ...state.Global,
  uniqueid: _get(state, 'Global.selectedLink.questionId', null),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(pageLayoutStyles)(
    injectIntl(PageLayout),
  ),
);
